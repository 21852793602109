/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        $(document).foundation(); // Foundation JavaScript

        
      
      },
      finalize: function() {

        const menu = document.getElementById("sub-header");
        const offset = menu.offsetTop;

        window.addEventListener("scroll", function() {
          if (window.scrollY >= offset) {
              menu.classList.add("sticky");
          } else {
              menu.classList.remove("sticky");
          }
        });
        
        //
        // Content Slides Navigation
        var $contentCarouselNav = $('.content-carousel-slides-navigation');

        $contentCarouselNav.slick({
          dots: false,
          fade: true,
          arrows: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
        });

        $('.content-carousel-navigation ul li').on('click', function() {
          var slideIndex = $(this).data('slide-index');
          $contentCarouselNav.slick('slickGoTo', slideIndex);
          $('.content-carousel-navigation ul li a').removeClass('active');
          $(this).find('a').addClass('active');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        var heroWrapper = document.querySelector('.home-hero-content-wrapper');
    
        if (!heroWrapper) {
            console.error('Hero wrapper not found!');
            return; // Exit if the hero section does not exist
        }
    
        // Calculate the height of the hero section
        var heroHeight = heroWrapper.offsetHeight;
    
        // Adjust this multiplier to control how long the section stays on screen
        var stayMultiplier = .1; 
        var triggerPoint = heroHeight * stayMultiplier;
    
        window.addEventListener('scroll', function () {
            var scrollPosition = window.scrollY;
    
            // Debug: Log scroll position and trigger point
            console.log('Scroll Position: ' + scrollPosition + ', Trigger Point: ' + triggerPoint);
    
            // Add or remove the "scrolled" class
            if (scrollPosition > triggerPoint) {
                heroWrapper.classList.add('scrolled');
                console.log('Added');
            } else {
                heroWrapper.classList.remove('scrolled');
                console.log('Removed');
            }
        });

        //window.addEventListener('scroll', function () {
        //  console.log(`Scroll Position: ${window.scrollY}, Trigger Point: ${heroHeight * stayDuration}`);
        //});

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template': {
      init: function() {
        
        //
        // Accordion
        $('.accordion-topic').click(function(){
          $(this).next('.accordion-response').slideToggle(500).toggleClass('current');
          $(this).toggleClass('current');
          $(this).parents('.accordion').siblings().find('.accordion-topic').slideUp(500);
          $(this).parents('.accordion').siblings().find('.accordion-response').removeClass('current');
        });

        //
        // Content Slides
        var $contentCarousel = $('.content-carousel-slides');

        $contentCarousel.on('init reInit afterChange', function (event, slick, currentSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1; // Convert to 1-based index
          $('.slide-index').text(i + '  /  ' + slick.slideCount);
        });

        $contentCarousel.slick({
          dots: false,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: $('.carousel-prev'),
          nextArrow: $('.carousel-next'),
        });

        

      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.








/*

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options });
});

$('#customers').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.gallery').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options  });
});

// Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/