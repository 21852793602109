/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        $(document).foundation(); // Foundation JavaScript

        // Get elements
        var primaryNav = document.querySelector('#primary-navigation');
        var loginPanel = document.querySelector('#login-panel');
        var hamburgerIcon = document.querySelector('.menu-hamburger-icon');
        var userIcon = document.querySelector('.menu-user-icon');
        var body = document.querySelector('body');


        // Function to reset all classes and styles
        function resetAllClasses() {
            // Remove all menu-related classes
            primaryNav.classList.remove('menu-open');
            hamburgerIcon.classList.remove('menu-open');
            loginPanel.classList.remove('login-open');
            userIcon.classList.remove('login-open');
            body.classList.remove('menu-open', 'login-open');

            // Hide panels
            primaryNav.style.display = 'none';
            loginPanel.style.display = 'none';
        }


        // Login panel click handler
        userIcon.addEventListener('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          
          // If login panel is already open (check by class), just reset everything
          if (loginPanel.classList.contains('login-open')) {
              resetAllClasses();
          } else {
              // Otherwise, reset and then show login panel
              resetAllClasses();
              loginPanel.classList.add('login-open');
              userIcon.classList.add('login-open');
              body.classList.add('login-open');
              loginPanel.style.display = 'block';
          }
        });

        // Hamburger menu click handler
        hamburgerIcon.addEventListener('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          
          // If menu is already open (check by class), just reset everything
          if (primaryNav.classList.contains('menu-open')) {
              resetAllClasses();
          } else {
              // Otherwise, reset and then show menu
              resetAllClasses();
              primaryNav.classList.add('menu-open');
              hamburgerIcon.classList.add('menu-open');
              body.classList.add('menu-open');
              primaryNav.style.display = 'block';
          }
        });


        // Submenu toggle handler
        var submenuLinks = document.querySelectorAll('.has-submenu > a');
        for (var i = 0; i < submenuLinks.length; i++) {
            submenuLinks[i].addEventListener('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                
                // Find the next ul element after this link
                var submenu = this.nextElementSibling;
                if (submenu && submenu.tagName === 'UL') {
                    // Toggle submenu visibility
                    var isVisible = submenu.style.display === 'block';
                    submenu.style.display = isVisible ? 'none' : 'block';
                    
                    // Toggle active class on parent li
                    this.parentElement.classList.toggle('submenu-active');
                }
            });
        }



        // Page Banner Hide:
          $('.banner-hide').on('click', function(){
            $('.page-banner-wrapper').hide();
          });

          // $('.card-cell-footer-learn-more').on('hover', function(){
          //   console.log('hello');
          // });

          // const cellHeader = document.querySelectorAll('.card-cell-header');
          // const learnMore = document.querySelectorAll('.card-cell-footer-learn-more');

          // cellHeader.forEach(element => {
          //   element.addEventListener('mouseover', () => {
          //     // Actions to perform when the mouse enters the element
          //     element.closest('.card-cell-product').classList.add('active');
          //   });
          
          //   element.addEventListener('mouseout', () => {
          //     // Actions to perform when the mouse leaves the element
          //     element.closest('.card-cell-product').classList.remove('active');
              
          //   });
          // });

          // learnMore.forEach(element => {
          //   element.addEventListener('mouseover', () => {
          //     // Actions to perform when the mouse enters the element
          //     element.closest('.card-cell-product').classList.add('active');
          //   });
          
          //   element.addEventListener('mouseout', () => {
          //     // Actions to perform when the mouse leaves the element
          //     element.closest('.card-cell-product').classList.remove('active');
              
          //   });
          // });

          


        function initMobileMenu() {
          const MOBILE_BREAKPOINT = 768; // Adjust breakpoint as needed
          const pageTitle = document.querySelector('.page-title');
          const marketingMenu = document.querySelector('.product-marketing--navigation');
      
          function handleMobileClick(e) {
              if (window.innerWidth <= MOBILE_BREAKPOINT) {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  if (marketingMenu?.tagName === 'UL') {
                      marketingMenu.classList.toggle('active');
                      pageTitle.classList.toggle('active');
                  }
              }
          }
      
          pageTitle?.addEventListener('click', handleMobileClick);
      }
      
      // Initialize when DOM is ready
      document.addEventListener('DOMContentLoaded', initMobileMenu);



        // Remove existing progress bars and prevent new ones
        jQuery(document).ready(function($) {
          if (typeof DLM_Progress !== 'undefined') {
              DLM_Progress.init = function() { return false; };
          }
      });
        
      
      },
      finalize: function() {

        
        // Announcement Bar Slides
        var $announcementCarousel = $('.announcement-bar-slick-slides');

        $announcementCarousel.slick({
          dots: false,
          arrows: true,
          infinite: true,
          speed: 400,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: $('.announcements-prev'),
          nextArrow: $('.announcements-next'),
        })
        .on("setPosition", function () {
          resizeSlider();
        });

        $(window).on("resize", function (e) {
          resizeSlider();
        });
        
        var slickHeight = $(".slick-track").outerHeight();
        var slideHeight = $(".slick-track").find(".slick-slide").outerHeight();
        
        function resizeSlider() {

          $(".slick-track")
            .find(".announcement").css("height", "auto");
            slickHeight = $(".slick-track").outerHeight();
            slideHeight = $(".slick-track").find(".slick-slide").outerHeight();

          $(".slick-track")
            .find(".announcement")
            .css("height", slickHeight + "px");
        }
        // END ANNOUNCEMENT SLIDER

        // Latest Downloads Slider
        var $latestDownloadsCarousel = $('.latest-dl-slick-slides');

        $latestDownloadsCarousel.slick({
          dots: false,
          arrows: true,
          infinite: true,
          gap: 20,
          speed: 400,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: $('.latest-dl-prev'),
          nextArrow: $('.latest-dl-next'),
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 1370,
              settings: {
                slidesToShow: 4,
              }
            },
          ]
        })





        //Support Product Selector Dropdown Helper


        $('input[name="dlm_download_category"]').on('change', function(){

          var chosen_product = $(this).parent().text();
          var chosen_product_slug = $(this).val();
          //console.log(chosen_product);

          $('button.dropdown-button').text( chosen_product );
          $('form#product_dropdown_form').attr('action', '/support/'+chosen_product_slug);

          $('#product-category-dropdown').foundation('close');

        });




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        
        var heroWrapper = document.querySelector('.home-hero-content-wrapper');
    
        if (!heroWrapper) {
            console.error('Hero wrapper not found!');
            return; // Exit if the hero section does not exist
        }
    
        // Calculate the height of the hero section
        var heroHeight = heroWrapper.offsetHeight;
    
        // Adjust this multiplier to control how long the section stays on screen
        var stayMultiplier = .1; 
        var triggerPoint = heroHeight * stayMultiplier;
    
        window.addEventListener('scroll', function () {
            var scrollPosition = window.scrollY;
    
            // Debug: Log scroll position and trigger point
            console.log('Scroll Position: ' + scrollPosition + ', Trigger Point: ' + triggerPoint);
    
            // Add or remove the "scrolled" class
            if (scrollPosition > triggerPoint) {
                heroWrapper.classList.add('scrolled');
                console.log('Added');
            } else {
                heroWrapper.classList.remove('scrolled');
                console.log('Removed');
            }
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'page': {
      init: function() {
        
        //
        // Accordion
        $('.accordion-topic').click(function(){
          $(this).next('.accordion-response').slideToggle(500).toggleClass('current');
          $(this).toggleClass('current');
          $(this).parents('.accordion').siblings().find('.accordion-topic').slideUp(500);
          $(this).parents('.accordion').siblings().find('.accordion-response').removeClass('current');
        });

        //
        // Content Slides
        var $contentCarousel = $('.content-carousel-slides');

        $contentCarousel.on('init reInit afterChange', function (event, slick, currentSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1; // Convert to 1-based index
          $('.slide-index').text(i + '  /  ' + slick.slideCount);
        });

        $contentCarousel.slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: $('.carousel-prev'),
          nextArrow: $('.carousel-next'),
        });


        // Content Slides Navigation
        var $contentCarouselNav = $('.content-carousel-slides-navigation');

        $contentCarouselNav.slick({
          dots: false,
          fade: true,
          arrows: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
        });

        $('.content-carousel-navigation ul li').on('click', function() {
          var slideIndex = $(this).data('slide-index');
          $contentCarouselNav.slick('slickGoTo', slideIndex);
          $('.content-carousel-navigation ul li a').removeClass('active');
          $(this).find('a').addClass('active');
        });

      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.








/*

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options });
});

$('#customers').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.gallery').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options  });
});

// Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/